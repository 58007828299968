<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="33%"
  >
    <div class="block">
      <span>请微信扫描下方二维码进行身份认证</span>
      <el-image :src="ewm"></el-image>
      <span>
        <el-button @click="getzt" size="small" type="primary">已认证</el-button>
      </span>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import crateQrcode from '@iebdc/plugins/createQRcode.js';
export default {
  data() {
    return {
      dialogVisible: false,
      ewm: '',
    };
  },
  computed: {
    // 流程进行中的数据
    ...mapState('wysq-data', ['flowInfoIng']),
  },
  methods: {
    // 获取二维码
    getEwm(row) {
      // let _this = this;
      this.ewm = row.ewmURL;
      this.dialogVisible = true;
    },
    //刷新实名状态
    getzt() {
      this.$emit('refresh');
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/pages/iebdc/styles/common-variables.scss';
@import '~@/pages/iebdc/styles/public.scss';
//弹框部分
.el-image {
  width: 200px;
  height: 200px;
  margin: 30px;
}
.block {
  text-align: center;
  /deep/.el-image {
    width: 258px;
    height: 260px;
    border: 1px solid #ffffff;
  }
  span {
    display: block;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .rowdisplay {
    display: inline-block;
    width: 100%;
    padding: $spacing-base $spacing-medium;
    .el-col {
      margin: $spacing-base 0;
      /deep/ .el-form-item {
        margin: 0;
        .el-form-item__content {
          text-align: left;
          line-height: 29px !important;
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
