import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const questionRecordService = {
  // 保存询问笔录信息
  save(data) {
    return request({
      url: REST_SERVICE.inquiryRecordController.save,
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data
    });
  },
  // 通过业务号和申请人id查询询问笔录详情
  getInquiryRecordByYwh(params) {
    return request({
      url: REST_SERVICE.inquiryRecordController.getInquiryRecordByYwh,
      method: 'get',
      params
    });
  },
  getMainInquiryRecordByYwh(params) {
    return request({
      url: REST_SERVICE.inquiryRecordController.getMainInquiryRecordByYwh,
      method: 'get',
      params
    });
  },
  // 打印询问笔录信息
  printInquiryRecord(data) {
    return request({
      url: REST_SERVICE.inquiryRecordController.printInquiryRecord,
      method: 'post',
      data
    });
  },
  // 取询问笔录pdf文件
  getFiles(params) {
    return request({
      url: REST_SERVICE.inquiryRecordController.getFiles,
      method: 'get',
      params
    });
  }
};

export default questionRecordService;
