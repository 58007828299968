<template>
  <div class="main box-shadow-default">
    <p class="type">
      业务类型：
      <span>{{ name }}</span>
    </p>
    <div class="filelist dragFileList">
      <div class="filelist-title flex flex-a_c flex-space-between">
        <span class="clmc">待签署文件</span>
        <!--

         -->
      </div>
      <div :class="signFiles.length ? 'wjylBox' : 'innerBox'">
        <div class="itemPic" v-for="(sign, i) in signFiles" :key="i">
          <p class="title">
            <span class="redTitle blackColor">{{ sign.clmc }}</span>
          </p>
          <div class="picture">
            <el-image :src="sign.PDFURL" ref="img" @click="open(sign.fileUrl)"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="wrap">
      <div class="title top">
        <span>认证信息<em class="tip">温馨小提示：可直接点击“授权认证”微信扫码在线签名及认证哟</em></span>
        <div class="btnWrap">
          <!-- <el-button size="small" type="primary" @click="retractApply()">撤回认证状态</el-button> -->
          <el-button size="small" type="primary" @click="sendMessage()">短信通知</el-button>
          <el-button size="small" type="primary" @click="refresh()">刷新</el-button>
        </div>
      </div>

      <el-table :data="tableData" style="width: 100%" fit align="center">
        <el-table-column prop="order" label="序号"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="sprz" label="认证状态">
          <template slot-scope="scope">
            <span :class="!scope.row.sprz ? 'redPoint' : 'greenPoint'">{{ scope.row.sprz ? '已认证' : '未认证' }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="xwbl" label="签名状态">
          <template slot-scope="scope">
            <span :class="!scope.row.xwbl ? 'redPoint' : 'redPoint1'">{{ scope.row.xwbl ? '已签名' : '未签名' }}</span>
          </template></el-table-column
        >

        <!-- <el-table-column prop="phone" label="签名方式" width="320px">
          <template slot-scope="scope">
            <el-radio-group v-model="tableData[scope.$index].signType">
              <el-radio :label="1" :disabled="scope.row.xwbl || isShow">签字板签名</el-radio>
              <el-radio :label="2" :disabled="scope.row.xwbl || isShow">微信签名</el-radio>
            </el-radio-group>
          </template>
        </el-table-column> -->

        <el-table-column fixed="right" label="操作" width="200px">
          <template slot-scope="scope">
            <!-- <el-button
              @click="signClick(scope.row)"
              type="text"
              size="small"
              :disabled="tableData[scope.$index].xwbl || isShow"
              v-if="scope.row.signType === 1"
              >签名板签名</el-button
            > -->
            <!-- :disabled="tableData[scope.$index].xwbl || isShow" -->

            <el-button type="text" size="small" @click="goPhoneSqrz(scope.row)">微信签名认证</el-button>
            <!-- <el-button
              :disabled="!scope.row.xwbl || isShow"
              type="text"
              size="small"
              @click="goPhoneSqrz(scope.row)"
              v-if="!scope.row.fprz"
              >微信认证</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>

      <span class="redPoint noPerson" v-if="tableData.length <= 0">暂无认证申请人可直接进入下一步</span>
    </div>
    <!-- <div class="wrap authWrap">
      <div class="title">授权认证操作指引</div>
      <div class="imgWrap">
        <img src="@/pages/iebdc/assets/images/sfrz.png" />
      </div>
    </div> -->
    <rzewm ref="rzewm" @refresh="refresh"></rzewm>
    <div class="main-footer text-center">
      <el-button type="primary" @click="prev()">上一步</el-button>
      <!--      <el-button type="primary" v-if="tableData.length > 0" @click="save()">提交申请</el-button>-->
      <!--      <el-button type="primary" v-if="tableData.length <= 0" @click="next()">下一步</el-button>-->
      <el-button type="primary" @click="next()">下一步</el-button>
    </div>
    <!-- 询问笔录弹窗 -->
    <question-record ref="questionRecord" @showSignButtonFn="showSignButtonFn" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import applicantCertifyService from '@/pages/iebdc/api/apply/applicant-certify';
import rzewm from '../component/rzewm';
import { isIe } from '@/pages/iebdc/utils/multibrowser';
import questionRecordService from '@/pages/iebdc/api/apply/question-record';
import { retractApply } from 'iebdc/api/my-application.js';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
var wgssSignatureSDK = null;
var sigObj = null;
var sigCtl = null;
var dynCapt = null;
import { REST_SERVICE } from '@iebdc/config/';
import questionRecord from '@/pages/iebdc/views/apply/flow/question-record';
import { printNotSignApply } from '@/pages/iebdc/api/my-application';
import applicantFinishService from '@/pages/iebdc/api/apply/applicant-finish';
import { printApply } from '@/pages/iebdc/api/my-application';
export default {
  name: 'authorizedCertify',
  components: { rzewm, questionRecord },
  data() {
    return {
      tableData: [],
      name: '',
      fileId: '',
      params: {
        sign: '',
        sqrId: '',
        ywh: '',
        isMainRecord: false,
        signIdCard: '',
        signName: '',
        signType: 1, //表示手写板签名
      },
      signFiles: [], //带签署文件
      imgPDF: require('@/pages/iebdc/assets/images/wysq/pdf.png'),
      imgMP4: require('@/pages/iebdc/assets/images/wysq/mp4.png'),
      qmfs: true, //签名方式默认是
      isShow: true, //
      uploadFiles: [], //已上传材料名称列表
    };
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-step', ['nextItem', 'prevItem']),
    ...mapState('wysq-data', ['flowInfoIng']),
  },
  created() {
    this.targetToStep('身份认证');
    this.setrzzt();
  },
  methods: {
    ...mapMutations('wysq-data', ['setFlowInfoIng']),
    // 跳转到下一页面
    ...mapMutations('wysq-step', ['targetToStep']),
    //解决项目打包上线后 点击el-image图片给body加上了overflow:hidden样式导致页面不可滑动问题
    removeStyle() {
      document.body.style.overflow = 'auto';
    },
    // 跳转到下一页面,需先校验当前页面数据状态 TODO
    next() {
      // if (!this.uploadFiles.includes('询问笔录') || !this.uploadFiles.includes('不动产登记申请书')) {
      //   return this.$message.error('请先填写完询问笔录，生成询问笔录PDF和申请表PDF');
      // }
      if (this.tableData.length <= 0) {
        this.$router.push({
          name: this.nextItem.name,
        });
      } else {
        //判断需认证申请人是否已全部认证和签名
        let tag = true;
        this.tableData.forEach((item) => {
          if (!item.sprz || !item.xwbl) {
            tag = false;
          }
        });
        if (tag) {
          this.$router.push({
            name: this.nextItem.name,
          });
        } else {
          this.$message.warning('请确认已全部签名和认证！');
        }
      }
    },
    // 打开询问笔录弹窗
    openQuestionRecord() {
      this.$refs.questionRecord.open();
    },
    //显示询问笔录pdf
    showSignButtonFn() {
      this.isShow = false;
      this.getFileList();
    },
    //刷新
    refresh() {
      this.applicantCertifyService();
      this.getFileList();
    },
    //发送短信
    async sendMessage() {
      const { success, data } = await applicantCertifyService.sendMsgToQlrAndYwr({ qlxxId: this.flowInfoIng.id });
      if (success && data) {
        this.$message.success('发送成功！');
      } else {
        this.$message.error(message);
      }
    },
    // 根据主业务号获取申请人的询问笔录/视频认证状态
    async applicantCertifyService() {
      if (!this.flowInfoIng.wwywh) return;
      const { success, data, message } = await applicantCertifyService.getSqrRzztByWwywh({
        wwywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        (data || []).forEach((item, i) => {
          item.order = i + 1;
          if (item.signType) {
            item.signType = Number(item.signType);
          }
        });
        this.tableData = data;
        console.log(data, 'data');
      } else {
        this.$message.error(message);
      }
    },

    //获取文件列表
    async getFileList() {
      if (!this.flowInfoIng.wwywh) return;
      const { success1, data1, message1 } = await printApply({ ywh: this.flowInfoIng.wwywh });
      const { success, data, message } = await applicantUploadService.getNoSignFileList({
        ywh: this.flowInfoIng.wwywh,
      });
      if (success && data) {
        this.signFiles = [];
        this.uploadFiles = [];
        (data || []).forEach((item) => {
          this.uploadFiles.push(item.clmc.substring(0, item.clmc.indexOf('.pdf')));
          // if (
          //   item.clmc.indexOf('询问笔录') > -1 ||
          //   item.clmc.indexOf('不动产登记申请书') > -1 ||
          //   item.clmc.indexOf('交易合同') > -1
          // ) {
          if (item.clmc.indexOf('.pdf') > -1 || item.clmc.indexOf('.mp4') > -1) {
            item.PDFURL = item.clmc.indexOf('.pdf') > -1 ? this.imgPDF : this.imgMP4;
            this.signFiles.push(item);
          }
          // }
        });
        if (
          this.uploadFiles.includes('询问笔录') &&
          this.uploadFiles.includes('不动产登记申请书') &&
          this.uploadFiles.includes('交易合同')
        ) {
          this.isShow = false;
        }
      } else {
        this.$message.error(message);
      }
    },
    //提交申请
    save() {
      //判断需认证申请人是否已全部认证
      let tag = true;
      this.tableData.forEach((item) => {
        if (!item.sprz) {
          tag = false;
        }
      });
      if (tag) {
        this.next();
      } else {
        this.$message.warning('认证状态全部通过后才能提交申请！');
      }
    },
    changeQm(row, val) {
      console.log(row, val, 566);
    },
    //手写板签名的函数方法
    restartSession() {
      wgssSignatureSDK = null;
      sigObj = null;
      sigCtl = null;
      dynCapt = null;
      wgssSignatureSDK = new WacomGSS_SignatureSDK(this.onDetectRunning, 8000);
      console.log(wgssSignatureSDK, 'wgssSignatureSDK');
      this.timedDetect();
    },
    timedDetect() {
      if (wgssSignatureSDK.running) {
        this.start();
      } else {
      }
    },
    onDetectRunning() {
      if (wgssSignatureSDK.running) {
        this.start();
      } else {
      }
    },
    start() {
      if (wgssSignatureSDK.running) {
        sigCtl = new wgssSignatureSDK.SigCtl(this.onSigCtlConstructor);
      }
    },

    onSigCtlConstructor(sigCtlV, status) {
      console.log(status, sigCtlV);
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        dynCapt = new wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
        // 去掉手写板的水印
        sigCtl.PutLicence(
          'eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI3YmM5Y2IxYWIxMGE0NmUxODI2N2E5MTJkYTA2ZTI3NiIsImV4cCI6MjE0NzQ4MzY0NywiaWF0IjoxNTYwOTUwMjcyLCJyaWdodHMiOlsiU0lHX1NES19DT1JFIiwiU0lHQ0FQVFhfQUNDRVNTIl0sImRldmljZXMiOlsiV0FDT01fQU5ZIl0sInR5cGUiOiJwcm9kIiwibGljX25hbWUiOiJTaWduYXR1cmUgU0RLIiwid2Fjb21faWQiOiI3YmM5Y2IxYWIxMGE0NmUxODI2N2E5MTJkYTA2ZTI3NiIsImxpY191aWQiOiJiODUyM2ViYi0xOGI3LTQ3OGEtYTlkZS04NDlmZTIyNmIwMDIiLCJhcHBzX3dpbmRvd3MiOltdLCJhcHBzX2lvcyI6W10sImFwcHNfYW5kcm9pZCI6W10sIm1hY2hpbmVfaWRzIjpbXX0.ONy3iYQ7lC6rQhou7rz4iJT_OJ20087gWz7GtCgYX3uNtKjmnEaNuP3QkjgxOK_vgOrTdwzD-nm-ysiTDs2GcPlOdUPErSp_bcX8kFBZVmGLyJtmeInAW6HuSp2-57ngoGFivTH_l1kkQ1KMvzDKHJbRglsPpd4nVHhx9WkvqczXyogldygvl0LRidyPOsS5H2GYmaPiyIp9In6meqeNQ1n9zkxSHo7B11mp_WXJXl0k1pek7py8XYCedCNW5qnLi4UCNlfTd6Mk9qz31arsiWsesPeR9PN121LBJtiPi023yQU8mgb9piw_a-ccciviJuNsEuRDN3sGnqONG3dMSA',
          this.onSigCtlPutLicence
        );
      } else {
      }
    },

    onSigCtlPutLicence(sigCtlV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        dynCapt = new wgssSignatureSDK.DynamicCapture(this.onDynCaptConstructor);
      } else {
      }
    },

    onDynCaptConstructor(dynCaptV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        sigCtl.GetSignature(this.onGetSignature);
      } else {
      }
    },

    onGetSignature(sigCtlV, sigObjV, status) {
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        sigObj = sigObjV;
        sigCtl.GetProperty('Component_FileVersion', this.onSigCtlGetProperty);
      } else {
      }
    },

    onSigCtlGetProperty(sigCtlV, property, status) {
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        dynCapt.GetProperty('Component_FileVersion', this.onDynCaptGetProperty);
      } else {
      }
    },

    onDynCaptGetProperty(dynCaptV, property, status) {
      if (wgssSignatureSDK.ResponseStatus.OK == status) {
        if ('function' === typeof callback) {
          callback();
        }
      } else {
      }
    },
    // 开始签名
    signClick(row) {
      this.params.sqrId = row.sqrId;
      this.params.ywh = this.flowInfoIng.wwywh;
      this.params.signIdCard = row.zjh;
      this.params.signName = row.name;
      console.log(wgssSignatureSDK.running);
      console.log(wgssSignatureSDK);
      if (!wgssSignatureSDK.running) {
        this.$confirm('暂未安装手写板驱动，是否立即安装？', '确认信息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          let url = REST_SERVICE.files.getPluginFileByName + '?fileName=' + 'jingeshouxieban.zip';
          console.log(url, 'url');
          let a = document.createElement('a');
          a.href = url;
          a.download = 'file';
          a.click();
        });
      } else {
      }
      dynCapt.Capture(sigCtl, 'who', 'why', null, null, this.onDynCaptCapture);
    },
    onDynCaptCapture(dynCaptV, SigObjV, status) {
      if (wgssSignatureSDK.ResponseStatus.INVALID_SESSION == status) {
        this.restartSession(window.Capture);
        console.log(window.Capture);
      } else {
        if (wgssSignatureSDK.DynamicCaptureResult.DynCaptOK != status) {
          console.log(status, 'status');
          if (status === 103) {
            // 已安装驱动未插入手写板
            this.$message.error('请检查手写板是否插入成功！');
          }
        }
        switch (status) {
          case wgssSignatureSDK.DynamicCaptureResult.DynCaptOK:
            sigObj = SigObjV;
            var flags = wgssSignatureSDK.RBFlags.RenderOutputPicture | wgssSignatureSDK.RBFlags.RenderColor24BPP;
            sigObj.RenderBitmap(
              'bmp',
              60, //设置图片宽度
              40, //设置图片高度
              2, //设置字体大小
              0x00000000, //设置字体颜色
              0x00ffffff, //设置背景颜色
              flags,
              0,
              0,
              this.onRenderBitmap
            );
            break;
          default:
            break;
        }
      }
    },
    onRenderBitmap(sigObjV, bmpObj, status) {
      console.log(bmpObj.image, 'bmpObj.image');
      this.tableData.forEach((item) => {
        if (this.params.sqrId === item.sqrId) {
          if (isIe()) {
            //IE浏览器
            this.params.sign = bmpObj.image.href;
          } else {
            this.params.sign = bmpObj.image.currentSrc;
          }
          if (this.params.sign) {
            this.saveInfo();
            console.log(this.tableData, 'tableData');
          }
        }
      });
    },
    saveInfo() {
      console.log(this.params, '5555');
      questionRecordService.save(this.params).then((res) => {
        console.log(res);
        if (res.success) {
          this.refresh();
        }
      });
    },
    //手写板签名的函数方法

    //获取认证二维码
    goPhoneSqrz(row) {
      this.$refs.rzewm.getEwm(row);
    },
    //打开链接
    open(url) {
      this.removeStyle();
      window.open(url);
    },
    //上一步
    prev() {
      this.targetToStep('身份认证');
      this.$router.push({
        name: this.prevItem.name,
        params: { ywh: this.flowInfoIng.wwywh, prev: true },
      });
    },
    //撤回认证
    retractApply() {
      this.$confirm('撤回认证状态后将会清空签名以及认证信息，是否撤回？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        retractApply({ id: this.flowInfoIng.id }).then((res) => {
          if (res.success === true) {
            this.$message.success('撤回成功！');
            this.$router.push({
              name: 'applicantTable',
              params: { ywh: this.flowInfoIng.wwywh },
            });
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    setrzzt() {
      applicantFinishService
        .fileUploadToQlxxStepByWwywh({
          wwywh: this.flowInfoIng.wwywh,
        })
        .then(() => {
          this.applicantCertifyService();
          this.getFileList();
          this.name = this.$store.getters.firstFlow ? this.$store.getters.firstFlow.thirdTypeData.name : '';
        })
        .catch(() => {
          this.$message.error('认证中修改失败');
        });
    },
  },
  mounted() {
    console.log(this.params, 'params');
    setTimeout(() => {
      this.restartSession();
    }, 3000);
  },
};
</script>

<style scoped lang="scss">
@import '~@/pages/iebdc/styles/public.scss';
@import '~@/pages/iebdc/styles/common-variables.scss';
.main {
  padding: 20px 40px;
  letter-spacing: 1px;
  background-color: $background-color-layout;
  p {
    margin: 0 0 10px;
    text-align: justify;
    text-justify: inter-ideograph;
    &.weight {
      font-weight: 700;
      color: rgba(85, 85, 85, 1);
    }
  }
  .wrap {
    // border: 1px solid #dddddd;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: #333333;
      line-height: 50px;
      padding-left: 10px;
      overflow: hidden;
      .btnWrap {
        width: 300px;
        float: right;
        text-align: right;
      }
      .tip {
        font-style: normal;
        color: red;
        font-size: 12px;
        margin-left: 10px;
      }
    }
    .top {
      padding-left: 0;
      margin-bottom: 10px;
      border-bottom: 1px solid #e6ebf5;
      span {
        float: left;
        padding: 0 5px;
        height: 34px;
        line-height: 30px;
        margin-top: 20px;
        border-bottom: 2px solid #2c8bff;
      }
    }
    .noPerson {
      line-height: 50px;
      margin-left: 10px;
    }
  }
  .authWrap {
    border: 1px solid #e6ebf5;
    .title {
      background: #f2f6fc;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
    }
    .imgWrap {
      width: 100%;
      height: 1100px;
      position: relative;
      img {
        width: 95%;
        height: 95%;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .type {
    span {
      font-weight: 700;
    }
  }
  /deep/ .el-table {
    border: 1px solid #e6ebf5;
    border-bottom: none !important;
  }
  /deep/ .el-table th {
    height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    background: #f2f6fc;
  }
  /deep/ .el-table tr {
    th:first-of-type,
    td:first-of-type {
      text-align: center;
    }
  }
  .redPoint {
    color: #ff5b24;
  }

  .redPoint1 {
    color: #5ceb0ae7;
  }
}
.filelist {
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  .filelist-title {
    background-color: #f6f6f6;
    padding: 0 20px;
    height: 60px;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    .clmc {
      font-size: 18px;
      color: #343434;
    }
    .tips {
      color: red;
      font-size: 14px;
      margin-right: $spacing-base;
    }
    .redPoint {
      color: red;
      font-size: 18px;
    }
    .hasMore {
      float: right;
      margin-top: 7px;
    }
  }
  .wjylBox {
    border-bottom: 1px solid #e5e5e5;
  }
  .innerBox {
    height: 180px;
    border-bottom: 1px solid #e5e5e5;
  }
  .filelist-list {
    display: flex;
    flex-wrap: wrap;
    min-height: 282px;
    background: #ffffff;
    > div {
      width: 25%;
    }
  }
  .itemPic {
    display: inline-block;
    width: 25%;
    text-align: center;
    padding-bottom: $spacing-medium;
    .title {
      line-height: 15px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      .redTitle {
        color: #d40000;
        font-size: 14px;
        font-weight: 700;
      }
      .blackColor {
        color: #353535;
      }
    }
    .picture {
      margin: 0 $spacing-medium $spacing-medium $spacing-medium;
      border: 1px dashed #2c8cff;
      height: 180px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      .el-image {
        display: flex;
        align-items: center;
        cursor: pointer;
        /deep/ .el-image__inner {
          width: initial;
          height: initial;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .minWidth {
      min-width: 80px;
    }
  }
}
</style>
