import request from '@/pages/iebdc/utils/request';
import { REST_SERVICE } from '@/pages/iebdc/config';
const applicantCertifyService = {
  // 根据主业务号获取申请人的询问笔录/视频认证状态
  getSqrRzztByWwywh(params) {
    return request({
      url: REST_SERVICE.apply.getSqrRzztByWwywh,
      method: 'get',
      params
    });
  },
  // 发送短信
  sendMsgToQlrAndYwr(params) {
    return request({
      url: REST_SERVICE.apply.sendMsgToQlrAndYwr,
      method: 'get',
      params
    });
  }
};

export default applicantCertifyService;