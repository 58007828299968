<template>
  <el-dialog :visible.sync="visible" center @close="close" :close-on-click-modal="false" width="60%">
    <!-- 标题 -->
    <div slot="title" class="header-title text-left">
      <span>询问笔录</span>
    </div>
    <!-- 抵押权登记询问笔录  -->
    <!-- <el-form ref="form" :rules="rules" :label-position="labelPosition" :model="form" label-width="80px" size="mini">
      <el-form-item label="被询问人" prop="signName">
        <el-input v-model="form.signName" disabled></el-input>
      </el-form-item>
      <el-form-item label="填写时间" prop="txsj">
        <el-date-picker v-model="form.txsj" type="date" disabled></el-date-picker>
      </el-form-item>
      <div class="question">
        <p>1、申请登记事项是否为申请人真实意思表示？</p>
        <el-form-item label="回答" prop="zsyt">
          <el-radio-group v-model="form.zsyt">
            <el-radio v-for="item in zsytOptions" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>2、申请登记的不动产是共有，单独所有，？</p>
        <el-form-item label="回答" prop="gyfs">
          <el-radio-group v-model="form.gyfs">
            <el-radio v-for="item in gyfsOptions" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
            <span class="feqk">占有份额情况:</span><el-input v-model="form.gyfe" :disabled="form.gyfs !== 2"></el-input>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>3、抵押人是否自愿将不动产抵押给抵押权人，已知悉抵押风险？</p>
        <el-form-item label="回答" prop="dyfx">
          <el-radio-group v-model="form.dyfx">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>4、抵押人是否已向抵押权人全面、真实地介绍了上述不动产的状况？</p>
        <el-form-item label="回答" prop="zk">
          <el-radio-group v-model="form.zk">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>5、抵押权人是否已充分了解抵押物现状？</p>
        <el-form-item label="回答" prop="dywxz">
          <el-radio-group v-model="form.dywxz">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>6、依法有权决定以上不动产抵押的其它权利人是否均已同意以上不动产抵押？抵押权人是否已知悉？</p>
        <el-form-item label="回答" prop="sfty">
          <el-radio-group v-model="form.sfty">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>7、其他需要询问的有关事项</p>
        <el-form-item label="请填写" prop="qt">
          <el-input v-model="form.qt"></el-input>
        </el-form-item>
      </div>
    </el-form> -->
    <!-- 非转移登记询问笔录  -->
    <el-form
      v-if="flowInfoIng.code !== 'N200104'"
      ref="form"
      :label-position="labelPosition"
      :model="form"
      label-width="100px"
      size="mini"
    >
      <el-form-item label="被询问人" prop="signName" :rules="[rules.required]">
        <el-input v-model="form.signName" disabled></el-input>
      </el-form-item>
      <el-form-item label="填写时间" prop="txsj" :rules="[rules.required]">
        <el-date-picker v-model="form.txsj" type="date" disabled></el-date-picker>
      </el-form-item>
      <div class="question">
        <p>1、申请登记事项是否为申请人真实意思表示？</p>
        <el-form-item label="回答" prop="zsyt" :rules="[rules.required]">
          <el-radio-group v-model="form.zsyt">
            <el-radio v-for="item in zsytOptions" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>2、申请登记的不动产是共有还是单独所有？如是共有，它的共有情况如何？</p>
        <el-form-item label="回答" prop="gyfs" :rules="[rules.required]">
          <el-radio-group v-model="form.gyfs">
            <el-radio v-for="item in gyfsOptions" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question" v-if="form.gyfs === 2">
        <p>占有份额情况:</p>
        <el-form-item label="回答" prop="gyfe" :rules="[rules.required]">
          <el-input v-model="form.gyfe"></el-input>
        </el-form-item>
      </div>
      <div class="question">
        <p>3、申请异议登记时，权利人是否不同意办理更正登记？</p>
        <el-form-item label="回答" prop="yysftygz">
          <el-radio-group v-model="form.yysftygz">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>4、申请异议登记时，是否已知悉异议不当应承受的责任？</p>
        <el-form-item label="回答" prop="sfyyzr">
          <el-radio-group v-model="form.sfyyzr">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>5、申请本次登记时，其它按份共有人是否同意？</p>
        <el-form-item label="回答" prop="gyrsfty" :rules="[rules.required]">
          <el-radio-group v-model="form.gyrsfty">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>6、其他需要询问的有关事项</p>
        <el-form-item label="请填写" prop="qt">
          <el-input v-model="form.qt"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <!-- 转移登记询问笔录  -->
    <el-form
      v-if="flowInfoIng.code === 'N200104'"
      ref="form"
      :label-position="labelPosition"
      :model="form"
      label-width="80px"
      size="mini"
    >
      <el-form-item label="被询问人" prop="signName" :rules="[rules.required]">
        <el-input v-model="form.signName" disabled></el-input>
      </el-form-item>
      <el-form-item label="填写时间" prop="txsj" :rules="[rules.required]">
        <el-date-picker v-model="form.txsj" type="date" disabled></el-date-picker>
      </el-form-item>
      <div class="question">
        <p>1、申请人与身份证明记载的主体是否一致？</p>
        <el-form-item label="回答" prop="jzztyz" :rules="[rules.required]">
          <el-radio-group v-model="form.jzztyz">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>2、申请登记事项是否为申请人真实意思表示？</p>
        <el-form-item label="回答" prop="zsyt" :rules="[rules.required]">
          <el-radio-group v-model="form.zsyt">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>3、申请登记的不动产是共有还是单独所有？如是共有，它的共有情况如何？</p>
        <el-form-item label="回答" prop="gyfs" :rules="[rules.required]">
          <el-radio-group v-model="form.gyfs">
            <el-radio v-for="item in gyfsOptions" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question" v-if="form.gyfs === 2">
        <p>占有份额情况:</p>
        <el-form-item label="请填写" prop="gyfe" :rules="[rules.required]">
          <el-input v-model="form.gyfe"></el-input>
        </el-form-item>
      </div>
      <div class="question">
        <p>4、是否已向受让方全面、真实地介绍了上述不动产，并自愿将该不动产相关权利转移给受让方？</p>
        <el-form-item label="回答" prop="srrzyzy" :rules="[rules.required]">
          <el-radio-group v-model="form.srrzyzy">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>5、受让方是否已全面了解上述不动产，并自愿受让该不动产相关权利、义务及责任？</p>
        <el-form-item label="回答" prop="srrzydz" :rules="[rules.required]">
          <el-radio-group v-model="form.srrzydz">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>6、申请本次交易登记时，其他共有人是否同意？</p>
        <el-form-item label="回答" prop="gyrsfty" :rules="[rules.required]">
          <el-radio-group v-model="form.gyrsfty">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>7、是否保证受让方受让的不动产无权利瑕疵，不受他人追索？</p>
        <el-form-item label="回答" prop="wxczs" :rules="[rules.required]">
          <el-radio-group v-model="form.wxczs">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>8、是否了解“满2减免增值税”和“满5唯一”减免个税？</p>
        <el-form-item label="回答" prop="ljsd" :rules="[rules.required]">
          <el-radio-group v-model="form.ljsd">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>9、所提交的承诺书是否真实、准确、有效？</p>
        <el-form-item label="回答" prop="sfzs" :rules="[rules.required]">
          <el-radio-group v-model="form.sfzs">
            <el-radio v-for="item in options" :label="item.label" :key="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="question">
        <p>10、其他需要询问的有关事项</p>
        <el-form-item label="请填写" prop="qt">
          <el-input v-model="form.qt"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <!-- 底部操作按钮 -->
    <span slot="footer">
      <el-button :loading="loading" type="primary" @click="save">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data';
import questionRecordService from '@/pages/iebdc/api/apply/question-record';
import { mapState } from 'vuex';
import { format } from 'date-fns';
export default {
  name: 'index',
  props: {
    showSignButtonFn: Function
  },
  data() {
    // this.rules = {
    //   sqrId: [{ required: true, message: '请填写受理编号', trigger: 'change' }],
    //   signName: [{ required: true, message: '请填写被询问人', trigger: 'change' }],
    //   txsj: [{ required: true, message: '请填写时间', trigger: 'change' }],
    //   zsyt: [{ required: true, message: '请选择', trigger: 'change' }],
    //   gyfs: [{ required: true, message: '请选择', trigger: 'change' }],
    //   dyfx: [{ required: true, message: '请选择', trigger: 'change' }],
    //   zk: [{ required: true, message: '请选择', trigger: 'change' }],
    //   dywxz: [{ required: true, message: '请选择', trigger: 'change' }],
    //   sfty: [{ required: true, message: '请选择', trigger: 'change' }],
    //   qt: [{ required: true, message: '请填写', trigger: 'change' }]
    // };
    return {
      labelPosition: 'right',
      // 弹窗状态
      visible: false,
      currentDate: new Date(),
      // 是否默认值
      zsytOptions: [
        {
          label: 1,
          value: '是'
        },
        {
          label: 0,
          value: '否'
        },
        {
          label: 2,
          value: '未回答'
        }
      ],
      options: [
        {
          label: 1,
          value: '是'
        },
        {
          label: 0,
          value: '否'
        }
      ],
      // 共有方式默认值
      gyfsOptions: [
        {
          label: 1,
          value: '共有'
        },
        {
          label: 0,
          value: '单独所有'
        },
        {
          label: 2,
          value: '按份共有'
        }
      ],
      // 加载动画
      loading: false,
      // 表单数据
      form: {
        ywh: '', // 业务号
        sqrId: '',
        signName: '', // 被询问人
        txsj: '', //填写时间
        zsyt: '', // 申请登记事项是否为申请人的真实意思表示(0:否,1:是，2:未回答)
        gyfs: '', // 申请登记的不动产是共有，还是单独所有(0:单独所有,1:共有)")
        // gyqk: '', // 申请登记的不动产是按份共有，还是共同共有(0:按份共有,1:共同共有)
        gyfe: '', // 共有份额
        zk: '', //状况
        dywxz: '', //抵押物现状
        dyfx: '', // 抵押方式
        sfty: '', //是否同意
        qt: '', // 其它说明事项
        isMainRecord: true //主申请人
      },
      rules: this.rules
    };
  },
  computed: {
    ...mapState('wysq-data', ['flowInfoIng'])
  },
  watch: {
    'form.gyfs': function(val) {
      if (val !== 2) {
        this.form.gyfe = '';
      }
    }
  },
  methods: {
    // 打开弹窗
    open() {
      // 获取数据
      this.visible = true;
      this.getData();
    },
    // 关闭弹窗
    close() {
      this.visible = false;
    },
    // 保存询问笔录信息
    save() {
      // 开始加载
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.form.gyfe && this.form.gyfs === 2) {
            this.$message.error('请输入占有份额情况！！！');
          } else {
            this.loading = true;
            questionRecordService
              .save(this.form)
              .then((res) => {
                if (res.success) {
                  this.$message.success('生成询问笔录成功');
                  this.$emit('showSignButtonFn');
                  this.close();
                }
              })
              .finally(() => {
                // 关闭加载动画
                this.loading = false;
              });
          }
        } else {
          this.$message.error('请填写完整！！！');
        }
      });
    },
    // 查询询问笔录信息
    getData() {
      // 查询参数
      const params = {
        ywh: this.form.ywh
      };
      // 获取询问笔录数据
      questionRecordService.getMainInquiryRecordByYwh(params).then((res) => {
        if (res.success && res.data) {
          const data = res.data;
          Object.keys(data).forEach((key) => {
            this.$set(this.form, key, data[key]);
          });
        }
      });
    }
  },
  mounted() {
    // 业务号
    this.form.ywh = this.flowInfoIng.wwywh;
    this.form.signName = localDataUser.get().name;
    this.form.sqrId = localDataUser.get().id;
    this.form.txsj = format(this.currentDate, 'yyyy-MM-dd');
  }
};
</script>

<style scoped lang="scss">
@import 'src/pages/iebdc/styles/public';
@import 'src/pages/iebdc/styles/common-variables';
.el-form {
  text-align: left;
}
.header-title {
  span {
    padding-bottom: 4px;
    font-weight: bold;
    border-bottom: 3px solid $color-primary;
  }
}
.question {
  p {
    padding: 15px;
    background-color: #f2f2f2;
    border-radius: $border-radius-small;
  }
  /deep/ .el-form-item--mini.el-form-item {
    margin-top: 18px;
  }
}
/deep/ .el-dialog--center .el-dialog__body {
  max-height: 450px;
  overflow-y: auto;
  @extend .scrollbar-default;
  padding: 30px 55px;
}
/deep/ .el-radio-group {
  display: inline-flex;
  align-items: center;
  .feqk {
    font-size: $font-size;
    width: 300px;
  }
}
</style>
